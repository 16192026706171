/* global process */
/**
 * Get the content API url based on the running context, server or browser.
 *
 * @returns {String} - Content API url
 */

export function getContentAPIURL() {
	return process.env.BUILD_TARGET === 'client' ? process.env.CONTENT_API_URL_CLIENT : process.env.CONTENT_API_URL_SERVER;
}

export function getContentBaseAPIURL() {
	return process.env.BUILD_TARGET === 'client' ? process.env.CONTENT_BASE_API_URL_CLIENT : process.env.CONTENT_BASE_API_URL_SERVER;
}

// eslint-disable-next-line require-await
export async function getSsoAPIURL(path) {
	// eslint-disable-next-line max-len
	return process.env.BUILD_TARGET === 'client' ? (process.env.CONTENT_BASE_API_URL_CLIENT+process.env.USER_SERVICE_CONTEXT + path) : (process.env.USER_SERVICE_BASE_API_URL + process.env.USER_SERVICE_CONTEXT + path);
}

export function getReCaptcha() {
	return process.env.REACT_APP_RECAPTCHA_KEY_SERVER;
}

